import * as React from 'react'
import { useDrag } from 'react-dnd'

import { DragType, CardStickerDragItem } from '../types'
import { Sticker } from './Sticker'
import { sheetStickerVariants } from './SheetSticker'
import { STICKER_ROTATION_RANGE } from '../constants'
import { randomBetween } from '../lib/randomBetween'

type Props = {
  id: string
  typeID: string
  x: number
  y: number
  rotation: number
}

const screenStickerVariants = {
  idle: {
    opacity: 1,
    transition: {
      duration: 0,
    },
  },
  dragging: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
}

export const ScreenSticker = ({ id, typeID, x, y, rotation }: Props) => {
  const [state, setState] = React.useState<keyof typeof sheetStickerVariants>(
    'idle',
  )

  const [, drag] = useDrag({
    item: {
      type: DragType.Sticker,
      id,
      typeID,
      rotation: randomBetween(...STICKER_ROTATION_RANGE),
      source: 'screen',
    } as CardStickerDragItem,
    begin: () => setState('dragging'),
    end: () => setState('idle'),
  })

  return (
    <Sticker
      ref={drag}
      animate={state}
      variants={screenStickerVariants}
      style={{
        position: 'absolute',
        left: x,
        top: y,
        filter: 'drop-shadow(0 1px 0px rgba(0, 0, 0, 0.3))',
      }}
      rotation={rotation}
      scaled={true}
      typeID={typeID}
    />
  )
}
