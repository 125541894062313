import { useRecoilState } from 'recoil'
import { useDrop } from 'react-dnd'
import { v4 as uuid } from 'uuid'

import * as state from '../state'

import { DragItem, DragType, Sticker as StickerT } from '../types'
import { __SERVER__ } from '../constants'

export const useScreenStickers = () => {
  const [, setCardStickers] = useRecoilState(state.cardStickers)
  const [, setScreenStickers] = useRecoilState(state.screenStickers)
  const [, drop] = useDrop({
    accept: DragType.Sticker,
    drop: (item: DragItem, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop) return

      const offset = monitor.getSourceClientOffset()
      if (!offset) return

      const sticker: StickerT = {
        id: item.id ?? uuid(),
        typeID: item.typeID,
        x: offset.x,
        y: offset.y,
        rotation: item.rotation,
      }

      setScreenStickers((curr) => {
        const existingIndex = curr.findIndex(
          (sticker) => sticker.id === item.id,
        )
        return existingIndex === -1
          ? [...curr, sticker]
          : [
              ...curr.slice(0, existingIndex),
              ...curr.slice(existingIndex + 1),
              sticker,
            ]
      })

      if (item.source === 'card')
        setCardStickers((curr) =>
          curr.filter((sticker) => sticker.id !== item.id),
        )
    },
  })

  return drop
}
