import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import { useCommonStyles } from '../hooks/useCommonStyles'

import { Inline } from './Inline'
import { XIcon } from './XIcon'
import { Text } from './Text'

type StartOverButtonProps = {} & BoxProps<'button'>

export const StartOverButton = ({
  className,
  ...props
}: StartOverButtonProps) => {
  const commonStyles = useCommonStyles()

  return (
    <Box
      as="button"
      {...props}
      className={clsx(
        commonStyles.shadow[2],
        commonStyles.focusYMovement,
        className,
      )}
      styles={{
        backgroundColor: 'mint.80',
        paddingTop: [1, 2, 2.5],
        paddingBottom: [1, 2, 2.5],
        paddingLeft: [3, 5],
        paddingRight: [3, 5],
        transitionProperty: 'transform',
        transitionDuration: 'normal',
        transitionTimingFunction: 'easeOut',
        ...props.styles,
      }}
    >
      <Inline space={[1.5, 2, 2.5]} alignY="center" align="center">
        <XIcon
          styles={{
            width: ['1rem', '1.25rem', '1.5rem'],
            display: 'block',
          }}
        />
        <Text
          variant="sansCond-14-20"
          styles={{
            color: 'black',
            textTransform: 'uppercase',
            letterSpacing: '.09em',
          }}
        >
          Start Over
        </Text>
      </Inline>
    </Box>
  )
}
