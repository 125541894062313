import * as React from 'react'
import { useRecoilState } from 'recoil'
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useTabsContext,
} from '@reach/tabs'
import { useDrop } from 'react-dnd'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import { CATEGORIES_ORDER } from '../constants'
import { useStickerTypes } from '../hooks/useStickerTypes'
import { useCommonStyles } from '../hooks/useCommonStyles'
import { DragItem, DragType, StickerSpec } from '../types'
import * as state from '../state'

import { Inline } from './Inline'
import {
  SheetSelectorButton,
  SheetSelectorButtonProps,
} from './SheetSelectorButton'
import { SheetSticker } from './SheetSticker'
import { ShareButton } from './ShareButton'
import { ReactComponent as AssetStickerSheetSVG } from '../assets/sticker-sheet.svg'
import { Icon } from './Icon'

type CategoryTabProps = {
  index: number
  icon: SheetSelectorButtonProps<'button'>['icon']
}

const SheetSelectorTab = ({ index, icon }: CategoryTabProps) => {
  const { selectedIndex } = useTabsContext()
  const isSelected = index === selectedIndex

  return (
    <Tab>
      <SheetSelectorButton
        variant={isSelected ? 'active' : 'idle'}
        size={['1.875rem', '2rem']}
        icon={icon}
      />
    </Tab>
  )
}

type SheetTabPanelProps = {
  categoryID: string
  scrollableHeight?: number
}

const SheetTabPanel = ({
  categoryID,
  scrollableHeight,
}: SheetTabPanelProps) => {
  const scrollableRef = React.useRef<HTMLDivElement>(null)
  const [isScrolledToTop, setIsScrolledToTop] = React.useState(true)
  const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false)
  const scrollCallback = () => {
    const scrollableEl = scrollableRef.current
    if (!scrollableEl) return

    const scrollTop = scrollableEl.scrollTop
    const scrollHeight = scrollableEl.scrollHeight
    const clientHeight = scrollableEl.clientHeight

    setIsScrolledToTop(scrollTop === 0)
    setIsScrolledToBottom(scrollHeight - clientHeight === scrollTop)
  }
  const scrollDown = () => {
    const scrollableEl = scrollableRef.current
    if (!scrollableEl) return

    scrollableEl.scrollBy({ behavior: 'smooth', top: 125 })
  }

  React.useEffect(() => {
    const scrollableEl = scrollableRef.current

    if (scrollableEl) scrollableEl.addEventListener('scroll', scrollCallback)

    return () => {
      if (scrollableEl)
        scrollableEl.removeEventListener('scroll', scrollCallback)
    }
  }, [])

  const stickerTypes = useStickerTypes()
  const categorizedStickerTypes = React.useMemo(
    () =>
      stickerTypes.reduce((acc, sticker) => {
        acc[sticker.category] = [...(acc[sticker.category] ?? []), sticker]
        return acc
      }, {} as Record<string, StickerSpec[]>),
    [stickerTypes],
  )
  const stickers = categorizedStickerTypes[categoryID]

  const commonStyles = useCommonStyles()

  return (
    <Box as={TabPanel} key={categoryID} styles={{ outline: 'none' }}>
      <Box styles={{ position: 'relative' }}>
        <Box
          className={commonStyles.grayScrollingFaderTop}
          styles={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '1rem',
            zIndex: 1,
            pointerEvents: 'none',
            transitionProperty: 'opacity',
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeOut',
            opacity: isScrolledToTop ? 0 : 100,
          }}
        />
        <Box
          ref={scrollableRef}
          styles={{
            paddingTop: [2.5, 5],
            overflow: 'auto',
            paddingLeft: [3, 6],
            paddingRight: [3, 6],
          }}
          style={{ height: scrollableHeight }}
        >
          <Box
            styles={{
              display: 'grid',
              gridTemplateColumns: [3, 4, 3, 4],
              alignItems: 'center',
              justifyItems: 'center',
              gap: 3,
              rowGap: [2, null, 3],
              paddingBottom: 6,
            }}
          >
            {stickers.map((sticker) => (
              <SheetSticker key={sticker.id} typeID={sticker.id} />
            ))}
          </Box>
        </Box>
        <Box
          className={commonStyles.grayScrollingFaderBottom}
          styles={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '2rem',
            zIndex: 1,
            pointerEvents: 'none',
            transitionProperty: 'opacity',
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeOut',
            opacity: isScrolledToBottom ? 0 : 100,
          }}
        >
          <Box
            as="button"
            onClick={scrollDown}
            styles={{
              cursor: 'pointer',
              position: 'absolute',
              top: ['50%', '25%'],
              left: '50%',
              width: '1.5rem',
              pointerEvents: isScrolledToBottom ? 'none' : 'auto',
            }}
          >
            <Icon
              name="chevronDown"
              className={clsx(
                commonStyles.centeredOffset,
                commonStyles.shadow[1],
              )}
              styles={{ color: 'white' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

type SheetProps = {
  scrollableHeight?: number
} & Omit<BoxProps<'div'>, 'as'>

export const Sheet = ({ scrollableHeight = 100, ...props }: SheetProps) => {
  const [tabIndex, setTabIndex] = React.useState(0)

  const [, setCardStickers] = useRecoilState(state.cardStickers)
  const [, setScreenStickers] = useRecoilState(state.screenStickers)
  const [, drop] = useDrop({
    accept: DragType.Sticker,
    drop: (item: DragItem, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop) return

      if (item.source === 'card')
        setCardStickers((curr) =>
          curr.filter((sticker) => sticker.id !== item.id),
        )

      if (item.source === 'screen')
        setScreenStickers((curr) =>
          curr.filter((sticker) => sticker.id !== item.id),
        )
    },
  })

  const commonStyles = useCommonStyles()

  return (
    <Box
      ref={drop}
      {...props}
      styles={{
        backgroundColor: 'gray.90',
        paddingTop: [6, 7],
        paddingLeft: [null, 2],
        paddingRight: [null, 2],
        position: 'relative',
        ...props.styles,
      }}
    >
      <Box
        as={AssetStickerSheetSVG}
        className={commonStyles.shadow[2]}
        styles={{
          display: 'block',
          width: ['7.625rem', '12rem'],
          transform: 'rotate(-3deg)',
          transformOrigin: 'bottomLeft',
          marginTop: -3.5,
          marginLeft: [null, null, -2],
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />
      <Box
        styles={{
          display: [null, null, 'none'],
          position: 'absolute',
          top: 0,
          right: 0,
          marginRight: [3, 6],
          marginTop: [-6, -8],
          zIndex: 1,
          width: ['4.5rem', '6rem'],
        }}
      >
        <ShareButton />
      </Box>
      <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
        <TabList>
          <Inline
            space={[2, 3]}
            align={['start', 'center']}
            styles={{
              position: 'relative',
              zIndex: 2,
              paddingLeft: 3,
              paddingRight: 3,
            }}
          >
            <SheetSelectorTab index={0} icon="smile" />
            <SheetSelectorTab index={1} icon="plus" />
            <SheetSelectorTab index={2} icon="abstract" />
            <SheetSelectorTab index={3} icon="cake" />
            <SheetSelectorTab index={4} icon="chat" />
            <SheetSelectorTab index={5} icon="symbols" />
          </Inline>
        </TabList>
        <TabPanels>
          {CATEGORIES_ORDER.map((categoryID) => {
            return (
              <SheetTabPanel
                key={categoryID}
                categoryID={categoryID}
                scrollableHeight={scrollableHeight}
              />
            )
          })}
        </TabPanels>
      </Tabs>
    </Box>
  )
}
