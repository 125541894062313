/**
 * Design tokens used to create atomic CSS class names. Calico is used to
 * convert these tokens into usable classes via `<Box>` and `useBoxStyles()`.
 *
 * @see https://github.com/WalltoWall/calico
 *
 * This file is used in conjunction with the following files to connect to
 * Calico.
 *
 *  - /src/theme.treat.ts
 *  - /types/calico.d.ts
 */

import {
  createCalicoTheme,
  mergeCalicoTokens,
  sensibleTokens,
  spaceQuarterRemTokens,
  grid12ColumnTokens,
} from '@walltowall/calico/treat'

export type Theme = typeof theme

export const colors = {
  white: '#fff',
  black: '#000',

  salmon: '#fbc5af',

  'mint.80': '#ddf2ce',

  'skyblue.75': '#95ceea',

  'brown.70': '#e9c49ee',
  'brown.60': '#c5a378',

  'gray.20': '#323232',
  'gray.40': '#646464',
  'gray.60': '#969696',
  'gray.70': '#c8c8c8',
  'gray.90': '#e7e7e7',

  debug: '#f0f',
  transparent: 'transparent',
} as const

export const theme = createCalicoTheme(
  mergeCalicoTokens(sensibleTokens, spaceQuarterRemTokens, grid12ColumnTokens, {
    breakpoints: {
      _: '0',
      tablet: '40rem',
      // The 0.1rem is to keep vertical iPad Pro on the tablet size.
      desktop: '64.1rem',
      desktopWide: '80.1rem',
    },

    rules: {
      color: colors,
      backgroundColor: colors,
      borderColor: colors,

      fontFamily: {
        sans: 'Barlow, system-ui, sans-serif',
        sansCond: 'trade-gothic-next-compressed, system-ui, sans-serif',
        sansExtCond: '"acumin-pro-extra-condensed", sans-serif',
      },

      fontSize: {
        '1rem': '1rem',
      },

      lineHeight: {
        1: '1',
      },

      height: {
        '0.625rem': '0.625rem',
        '0.875rem': '0.875rem',
        '1rem': '1rem',
        '1.25rem': '1.25rem',
        '1.5rem': '1.5rem',
        '1.875rem': '1.875rem',
        '2rem': '2rem',
        '2.5rem': '2.5rem',
        '3rem': '3rem',
        '3.125rem': '3.125rem',
        '3.3125rem': '3.3125rem',
        '3.4375rem': '3.4375rem',
        '3.5rem': '3.5rem',
        '4rem': '4rem',
        '4.5rem': '4.5rem',
        '5.5rem': '5.5rem',
        '8rem': '8rem',
        '8.5rem': '8.5rem',
        '13rem': '13rem',
        '6/12': (6 / 12) * 100 + '%',
        '7/12': (7 / 12) * 100 + '%',
        '10/12': (10 / 12) * 100 + '%',
        '630px': '630px',
      },

      width: {
        '0.625rem': '0.625rem',
        '0.75rem': '0.75rem',
        '0.875rem': '0.875rem',
        '1rem': '1rem',
        '1.25rem': '1.25rem',
        '1.5rem': '1.5rem',
        '1.875rem': '1.875rem',
        '2rem': '2rem',
        '2.5rem': '2.5rem',
        '3.125rem': '3.125rem',
        '3.3125rem': '3.3125rem',
        '3.4375rem': '3.4375rem',
        '3.45rem': '3.45rem',
        '3.5rem': '3.5rem',
        '3.625rem': '3.625rem',
        '4rem': '4rem',
        '4.5rem': '4.5rem',
        '5rem': '5rem',
        '5.5rem': '5.5rem',
        '6rem': '6rem',
        '7.5rem': '7.5rem',
        '7.625rem': '7.625rem',
        '8.5rem': '8.5rem',
        '9rem': '9rem',
        '11rem': '11rem',
        '12rem': '12rem',
        '1200px': '1200px',
      },

      minHeight: {
        full: '100%',
        '100vh': '100vh',
      },

      maxWidth: {
        none: 'none',
        '17ch': '17ch',
        '22ch': '22ch',
        '13rem': '13rem',
        small: '24rem',
        medium: '48rem',
        large: '64rem',
        xlarge: '75rem',
        xxlarge: '90rem',
        '3.125rem': '3.125rem',
        '32rem': '32rem',
        '36rem': '36rem',
        full: '100%',
        '90%': '90%',
      },

      transitionDuration: {
        slow: '300ms',
        normal: '200ms',
        fast: '100ms',
      },

      transitionProperty: {
        backgroundColor: 'background-color',
        color: 'color',
        colorAndBackgroundColor: 'background-color, color',
        opacity: 'opacity',
        transform: 'transform',
        colorAndBorderColor: 'color, border-color',
      },

      maxHeight: {
        '90%': '90%',
        full: '100%',
      },

      cursor: {
        grab: 'grab',
      },

      textDecoration: {
        none: 'none',
        underline: 'underline',
      },

      borderWidth: {
        '2px': '2px',
        '3px': '3px',
        '4px': '4px',
      },

      borderRadius: {
        '50%': '50%',
        8: '8px',
        10: '10px',
        '10px': '10px',
        '12px': '12px',
        '13px': '13px',
        '15px': '15px',
        '1.5rem': '1.5rem',
      },

      borderTopLeftRadius: {
        '1rem': '1rem',
      },

      borderTopRightRadius: {
        '1rem': '1rem',
      },

      letterSpacing: {
        '.03em': '.03em',
        '.09em': '.09em',
      },

      left: {
        '50%': '50%',
        '100%': '100%',
      },
      top: {
        '25%': '25%',
        '50%': '50%',
        '100%': '100%',
      },
      transform: {
        'x--50%': 'translateX(-50%)',
        'y--50%': 'translateY(-50%)',
        'y-50%': 'translateY(50%)',
        'xy--50%': 'translate(-50%, -50%)',
        'rotate(-3deg)': 'rotate(-3deg)',
        'rotate(5deg)': 'rotate(5deg)',
        'rotate(22deg)': 'rotate(22deg)',
      },
      objectFit: {
        cover: 'cover',
        contain: 'contain',
      },
      gridTemplateColumns: {
        2: 'repeat(2, minmax(0, 1fr))',
        4: 'repeat(4, minmax(0, 1fr))',
        '1fr auto': 'minmax(0, 1fr) auto',
      },
      gridTemplateRows: {
        '1fr auto': 'minmax(0, 1fr) auto',
        'auto 1fr': 'auto minmax(0, 1fr)',
      },
      gridColumnStart: {
        1: '1',
        2: '2',
      },
      transformOrigin: {
        bottomLeft: 'bottom left',
        bottomRight: 'bottom right',
      },
      rowGap: {
        2: '0.5rem',
        3: '0.75rem',
        6: '1.5rem',
      },
      gridColumn: {
        1: '1',
        2: '2',
      },

      backgroundSize: {
        '100%': '100%',
      },
    },

    pseudos: {
      color: [':hover', ':focus'],
      backgroundColor: [':hover', ':focus'],
      opacity: [':hover', ':focus'],
    },
  } as const),
)

export const mediaQueries = theme.mediaQueries
