import * as React from 'react'
import { useDrag } from 'react-dnd'

import { STICKER_ROTATION_RANGE } from '../constants'
import { randomBetween } from '../lib/randomBetween'
import { CardStickerDragItem, DragType } from '../types'

import { Sticker } from './Sticker'

type Props = {
  typeID: string
}

export const sheetStickerVariants = {
  idle: {
    opacity: 1,
    transition: {
      duration: 0,
    },
  },
  dragging: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
}

export const SheetSticker = ({ typeID }: Props) => {
  const [state, setState] = React.useState<keyof typeof sheetStickerVariants>(
    'idle',
  )

  const [, drag] = useDrag({
    item: {
      type: DragType.Sticker,
      typeID: typeID,
      rotation: randomBetween(...STICKER_ROTATION_RANGE),
      source: 'sheet',
    } as CardStickerDragItem,
    begin: () => setState('dragging'),
    end: () => setState('idle'),
  })

  return (
    <Sticker
      ref={drag}
      animate={state}
      variants={sheetStickerVariants}
      typeID={typeID}
      reduceDragAreaOnMobile={true}
      style={{
        width: '100%',
        height: '100%',
        filter: 'drop-shadow(0 1px 0px rgba(0, 0, 0, 0.3))',
      }}
    />
  )
}
