import * as React from 'react'
import { motion, MotionProps } from 'framer-motion'
import { useRecoilValue } from 'recoil'

import * as state from '../state'
import { useStickerTypesByID } from '../hooks/useStickerTypesByID'
import { Box } from '@walltowall/calico'

type StickerShadowProps = MotionProps & {
  scaled?: boolean
  typeID: string
  rotation: number
}

export const StickerShadow = React.forwardRef<
  HTMLDivElement,
  StickerShadowProps
>(({ scaled = false, typeID, rotation, ...props }, ref) => {
  const scale = useRecoilValue(state.scale)

  const stickerTypes = useStickerTypesByID()
  const stickerType = stickerTypes[typeID]

  return (
    <motion.div
      {...props}
      ref={ref}
      style={{
        width: stickerType.baseWidth * (scaled ? scale : 1),
        height: stickerType.baseHeight * (scaled ? scale : 1),
        transformOrigin: 'center',
        userSelect: 'none',
        WebkitTouchCallout: 'none',
        touchAction: 'none',
        ...props.style,
      }}
    >
      <Box
        as="img"
        src={stickerType.imageUrl}
        styles={{
          display: 'block',
          width: 'full',
          height: 'full',
          opacity: 25,
        }}
        style={{
          transform: rotation ? `rotate(${rotation}deg)` : undefined,
          filter: 'brightness(0) blur(3px)',
        }}
      />
    </motion.div>
  )
})
