import * as React from 'react'
import { useRecoilValue } from 'recoil'

import * as state from '../state'

import { ScreenSticker } from './ScreenSticker'

export const ScreenStickers = () => {
  const screenStickers = useRecoilValue(state.screenStickers)

  return (
    <>
      {screenStickers.map((sticker) => (
        <ScreenSticker
          key={sticker.id}
          id={sticker.id}
          typeID={sticker.typeID}
          x={sticker.x}
          y={sticker.y}
          rotation={sticker.rotation}
        />
      ))}
    </>
  )
}
