import * as React from 'react'
import { use100vh } from 'react-div-100vh'
import { useMedia } from 'react-media'
import { useRecoilValue } from 'recoil'
import useMeasure from 'react-use-measure'
import VisuallyHidden from '@reach/visually-hidden'
import { Box } from '@walltowall/calico'
import clsx from 'clsx'

import {
  __SERVER__,
  HTML2CANVAS_CARD_ID,
  HTML2CANVAS_OG_IMAGE_ID,
  WW_SHOP_URL,
} from '../constants'
import { mediaQueries } from '../theme'
import { useCommonStyles } from '../hooks/useCommonStyles'
import { useScreenStickers } from '../hooks/useScreenStickers'
import { useDebug } from '../hooks/useDebug'
import * as state from '../state'

import { Layout } from '../components/Layout'
import { Card } from '../components/Card2'
import { Sheet } from '../components/Sheet2'
import { Header } from '../components/Header'
import { ShareButton } from '../components/ShareButton'
import { Text } from '../components/Text'
import { BackgroundPicker } from '../components/BackgroundPicker'
import { DragLayer } from '../components/DragLayer'
import { ScreenStickers } from '../components/ScreenStickers'
import { OpenGraphImage } from '../components/OpenGraphImage'
import { SocialButton } from '../components/SocialButton'

import { ReactComponent as AssetBackgroundsSVG } from '../assets/backgrounds.svg'

const useContainerHeightStyles = () => {
  const viewportHeight = use100vh()
  const mqMatches = useMedia({ queries: mediaQueries })

  if (__SERVER__ || !viewportHeight) return 0
  if (mqMatches.desktop) return { minHeight: viewportHeight ?? undefined }
  if (mqMatches._) return { height: viewportHeight ?? undefined }

  return undefined
}

const useSheetScrollableHeight = (
  headerContainerHeight: number,
  cardContainerHeight: number,
  leftSideContainerHeight: number,
  introAndBackgroundsContainerHeight: number,
) => {
  const viewportHeight = use100vh()
  const mqMatches = useMedia({ queries: mediaQueries })

  if (__SERVER__ || !viewportHeight) return 0

  if (mqMatches.desktopWide)
    return leftSideContainerHeight - introAndBackgroundsContainerHeight - 108

  if (mqMatches.desktop)
    return leftSideContainerHeight - introAndBackgroundsContainerHeight - 100

  if (mqMatches.tablet)
    return viewportHeight - headerContainerHeight - cardContainerHeight - 107

  if (mqMatches._)
    return viewportHeight - headerContainerHeight - cardContainerHeight - 54

  return undefined
}

export const IndexPage = () => {
  const [headerContainerRef, headerContainerBounds] = useMeasure()
  const [cardContainerRef, cardContainerBounds] = useMeasure()
  const [leftSideContainerRef, leftSideContainerBounds] = useMeasure()
  const [
    introAndBackgroundsContainerRef,
    introAndBackgroundsContainerBounds,
  ] = useMeasure()

  const containerHeightStyles = useContainerHeightStyles()
  const sheetScrollableHeight = useSheetScrollableHeight(
    headerContainerBounds.height,
    cardContainerBounds.height,
    leftSideContainerBounds.height,
    introAndBackgroundsContainerBounds.height,
  )

  const screenStickersRef = useScreenStickers()
  const cardSize = useRecoilValue(state.cardSize)
  const debug = useDebug()
  const commonStyles = useCommonStyles()

  return (
    <Layout>
      <Box
        id={HTML2CANVAS_OG_IMAGE_ID}
        className={clsx(!debug && commonStyles.totalOffset)}
        styles={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
          zIndex: 3,
        }}
      >
        <OpenGraphImage />
      </Box>
      <Box
        id={HTML2CANVAS_CARD_ID}
        className={commonStyles.totalOffset}
        styles={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
        style={{ width: cardSize }}
      >
        <Card
          isFlatReplica={true}
          withOutline={false}
          withStartOverButton={false}
          styles={{ width: 'full' }}
        />
      </Box>
      <Box
        ref={screenStickersRef}
        styles={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: [null, null, 9, 12],
          paddingRight: [null, null, 9, 12],
          paddingBottom: [null, null, 12],
          justifyContent: [null, null, 'center'],
          overflow: 'hidden',
        }}
        style={{ ...containerHeightStyles }}
      >
        <Box ref={headerContainerRef} styles={{ flexShrink: 0 }}>
          <Header />
        </Box>
        <Box
          styles={{
            flexGrow: [1, null, 0],
            flexDirection: 'column',
            display: ['flex', null, 'grid'],
            gridAutoFlow: [null, null, 'columnDense'],
            gridTemplateColumns: [null, null, 2],
            gap: [null, null, 9, 16],
            backgroundColor: 'white',
            borderRadius: [null, null, '15px'],
            paddingTop: [null, null, 7, 12],
            paddingBottom: [null, null, 15, 18],
            paddingLeft: [null, null, 7, 12],
            paddingRight: [null, null, 7, 12],
            width: 'full',
            maxWidth: 'xxlarge',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Box
            styles={{
              flexShrink: 0,
              position: 'relative',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              paddingBottom: [null, 12, 0],
              gridColumn: [null, null, 2],
              marginTop: [null, null, -14, -24],
            }}
          >
            <Box
              styles={{
                display: [null, null, 'none'],
                height: '3rem',
                backgroundColor: 'salmon',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
              }}
            />
            <Box
              styles={{
                display: [null, 'none'],
                height: '3rem',
                backgroundColor: 'gray.90',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />
            <Box
              ref={cardContainerRef}
              styles={{
                width: 'full',
                position: 'relative',
                paddingLeft: [3, 0],
                paddingRight: [2, 0],
              }}
            >
              <Card
                styles={{
                  width: 'full',
                  maxWidth: [null, '32rem', 'none'],
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
              <Box
                styles={{
                  display: ['none', null, 'block'],
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  zIndex: 1,
                  marginRight: 8,
                  marginBottom: -24,
                  width: '8.5rem',
                }}
              >
                <ShareButton />
              </Box>
            </Box>
          </Box>
          <Box
            ref={leftSideContainerRef}
            styles={{
              flexGrow: 1,
              display: [null, null, 'grid'],
              gridTemplateRows: 'auto 1fr',
              gap: [null, null, 10, 12],
              gridColumn: [null, null, 1],
            }}
          >
            <Box
              ref={introAndBackgroundsContainerRef}
              styles={{
                display: ['none', null, 'grid'],
                gridTemplateColumns: '1fr auto',
                gap: [null, null, 6, 8],
                paddingTop: [null, null, 6],
                paddingLeft: [null, null, 3, 6],
                paddingRight: [null, null, 3, 6],
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Box
                styles={{
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  marginTop: -8,
                  marginLeft: -30,
                  width: '8.5rem',
                  transform: 'rotate(22deg)',
                  transformOrigin: 'bottomRight',
                }}
              >
                <VisuallyHidden>Backgrounds</VisuallyHidden>
                <Box
                  as={AssetBackgroundsSVG}
                  className={commonStyles.shadow[2]}
                  styles={{ display: 'block' }}
                />
              </Box>
              <Text
                variant="sans-16-20"
                styles={{
                  color: 'gray.20',
                  maxWidth: '22ch',
                  justifySelf: 'center',
                }}
              >
                Choose a background, then assemble your very own message using
                our limited selection of time-sensitive word-stickers.
              </Text>
              <BackgroundPicker />
            </Box>
            <Box>
              <Sheet
                scrollableHeight={sheetScrollableHeight}
                styles={{ height: 'full', borderRadius: [null, null, '10px'] }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          styles={{
            gridAutoFlow: 'column',
            gap: 6,
            display: ['none', null, 'grid'],
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 8,
          }}
        >
          <SocialButton variant="instagram" />
          <SocialButton variant="facebook" />
          <SocialButton variant="twitter" />
          <Box
            as="a"
            href={WW_SHOP_URL}
            target="_blank"
            className={clsx(
              commonStyles.shadow[2],
              commonStyles.focusYMovement,
            )}
            styles={{
              display: 'block',
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 5,
              paddingRight: 5,
              backgroundColor: 'black',
              color: 'white',
              justifySelf: 'center',
              transitionProperty: 'transform',
              transitionDuration: 'normal',
              transitionTimingFunction: 'easeOut',
            }}
          >
            <Text
              variant="sansCond-14-20"
              styles={{
                textTransform: 'uppercase',
                letterSpacing: '.09em',
              }}
            >
              Get the real cards at the W|W shop
            </Text>
          </Box>
        </Box>
        <ScreenStickers />
        <DragLayer />
      </Box>
    </Layout>
  )
}

export default IndexPage
