import * as React from 'react'
import { motion, MotionProps } from 'framer-motion'
import { useRecoilValue } from 'recoil'
import { Box, BoxProps } from '@walltowall/calico'
import { AspectRatio } from '@walltowall/siamese'

import * as state from '../state'
import { useStickerTypesByID } from '../hooks/useStickerTypesByID'
import { useCommonStyles } from '../hooks/useCommonStyles'
import { useDebug } from '../hooks/useDebug'

type StickerProps = MotionProps & {
  scaled?: boolean
  typeID: string
  rotation?: number
  staticWidth?: NonNullable<BoxProps['styles']>['width']
  reduceDragAreaOnMobile?: boolean
}

export const Sticker = React.forwardRef<HTMLDivElement, StickerProps>(
  (
    {
      scaled = false,
      typeID,
      rotation,
      style,
      staticWidth,
      reduceDragAreaOnMobile = false,
      ...props
    },
    ref,
  ) => {
    const scale = useRecoilValue(state.scale)

    const stickerTypes = useStickerTypesByID()
    const stickerType = stickerTypes[typeID]

    const commonStyles = useCommonStyles()
    const debug = useDebug()

    return (
      <motion.div
        {...props}
        style={{
          transformOrigin: 'center',
          userSelect: 'none',
          WebkitTouchCallout: 'none',
          touchAction: 'pan-y',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'grab',
          ...style,
        }}
      >
        <Box
          as={AspectRatio}
          x={stickerType.baseWidth}
          y={stickerType.baseHeight}
          styles={{
            width: staticWidth,
          }}
          style={{
            width: staticWidth
              ? undefined
              : scaled
              ? stickerType.baseWidth * scale
              : 1000,
            maxWidth: staticWidth
              ? undefined
              : scaled
              ? undefined
              : stickerType.baseWidth * 1.35,
            transform: rotation ? `rotate(${rotation}deg)` : undefined,
          }}
        >
          <Box
            as="img"
            src={stickerType.imageUrl}
            styles={{
              display: 'block',
              width: 'full',
              height: 'full',
            }}
          />
          <Box
            ref={ref}
            className={commonStyles.centeredOffsetX}
            styles={{
              width: ['8/12', 'full'],
              position: 'absolute',
              top: 0,
              left: '50%',
              bottom: 0,
              backgroundColor: debug ? 'debug' : undefined,
            }}
          />
        </Box>
      </motion.div>
    )
  },
)
