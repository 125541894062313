import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'
import VisuallyHidden from '@reach/visually-hidden'
import clsx from 'clsx'

import { useShareButton } from '../hooks/useShareButton'
import { useCommonStyles } from '../hooks/useCommonStyles'

import { ReactComponent as AssetShareButtonSVG } from '../assets/share.svg'

type ShareButtonProps = Omit<BoxProps<'button'>, 'as'>

export const ShareButton = ({ className, ...props }: ShareButtonProps) => {
  const onShareClick = useShareButton()
  const commonStyles = useCommonStyles()

  return (
    <Box
      as="button"
      onClick={onShareClick}
      id="share-button"
      {...props}
      className={clsx(
        commonStyles.shadow[2],
        commonStyles.focusYMovement,
        className,
      )}
      styles={{
        display: 'block',
        width: 'full',
        transitionProperty: 'transform',
        transitionDuration: 'normal',
        transitionTimingFunction: 'easeOut',
        ...props.styles,
      }}
    >
      <VisuallyHidden>Share</VisuallyHidden>
      <Box as={AssetShareButtonSVG} styles={{ display: 'block' }} />
    </Box>
  )
}
