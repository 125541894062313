import * as React from 'react'
import { useRecoilState } from 'recoil'
import VisuallyHidden from '@reach/visually-hidden'
import { Box, BoxProps } from '@walltowall/calico'

import { CARD_THUMB_IMAGE_URLS } from '../constants'
import * as state from '../state'

type BackgroundPickerProps = BoxProps<'div'>

export const BackgroundPicker = (props: BackgroundPickerProps) => {
  const [cardImageIndex, setCardImageIndex] = useRecoilState(
    state.cardImageIndex,
  )

  return (
    <Box
      {...props}
      styles={{
        display: 'grid',
        gridTemplateColumns: 3,
        gap: [3, null, null, 5],
        position: 'relative',
        ...props.styles,
      }}
    >
      {CARD_THUMB_IMAGE_URLS.map((imageUrl, index) => {
        const isSelected = index === cardImageIndex

        return (
          <Box
            as="button"
            key={imageUrl}
            onClick={() => setCardImageIndex(index)}
            styles={{
              backgroundColor: 'gray.90',
              width: ['3.5rem', null, null, '4.5rem'],
              height: ['3.5rem', null, null, '4.5rem'],
              borderRadius: '10px',
              backgroundSize: 'cover',
              position: 'relative',
            }}
          >
            <Box
              styles={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderWidth: isSelected
                  ? ['2px', null, null, '3px']
                  : undefined,
                borderColor: 'black',
                borderStyle: 'solid',
                borderRadius: '10px',
                opacity: isSelected ? 100 : 0,
                transitionProperty: 'opacity',
                transitionDuration: 'normal',
                transitionTimingFunction: 'easeOut',
                pointerEvents: 'none',
                marginTop: -1.5,
                marginLeft: -1.5,
                marginBottom: 1,
                marginRight: 1,
              }}
            />
            <VisuallyHidden>Select background</VisuallyHidden>
            <Box
              as="img"
              styles={{
                width: 'full',
                height: 'full',
                objectFit: 'cover',
                borderRadius: '10px',
                pointerEvents: 'none',
              }}
              src={imageUrl}
            />
          </Box>
        )
      })}
    </Box>
  )
}
