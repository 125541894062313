/**
 * Collection of commonly used types throughout the project.
 */

export enum DragType {
  Sticker = 'Sticker',
}

export enum DropType {
  Card = 'Card',
}

export type DragItem = CardStickerDragItem

export type SheetStickerDragItem = {
  type: DragType.Sticker
  stickerType: string
}

export type CardStickerDragItem = {
  id: string
  type: DragType.Sticker
  typeID: string
  rotation: number
  source: 'card' | 'screen' | 'sheet'
}

export interface StickerSpec {
  id: string
  imageUrl: string
  name: string
  category: string
  baseWidth: number
  baseHeight: number
}

export interface Sticker {
  id: string
  typeID: string
  x: number
  y: number
  rotation: number
}

export type UnwrappedArray<T> = T extends (infer U)[] ? U : T

export type PickPartial<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>> &
  Partial<Pick<T, K>>
