import * as React from 'react'
import { useStyles } from 'react-treat'
import { useRecoilState } from 'recoil'
import VisuallyHidden from '@reach/visually-hidden'
import { AspectRatio } from '@walltowall/siamese'
import { Box, BoxProps } from '@walltowall/calico'
import { useDrop } from 'react-dnd'
import useMeasure from 'react-use-measure'
import mergeRefs from 'react-merge-refs'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'

import { CARD_IMAGE_URLS } from '../constants'
import { DragItem, DragType, Sticker as StickerT } from '../types'
import { useCommonStyles } from '../hooks/useCommonStyles'
import * as state from '../state'

import { CardSticker } from './CardSticker'
import { ChevronButton } from './ChevronButton'
import { StartOverButton } from './StartOverButton'

import * as styleRefs from './Card2.treat'

const useCardDrop = () => {
  const ref = React.useRef<HTMLDivElement>()
  const [, setScreenStickers] = useRecoilState(state.screenStickers)
  const [, setCardStickers] = useRecoilState(state.cardStickers)

  const [, drop] = useDrop({
    accept: DragType.Sticker,
    drop: (item: DragItem, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop || !ref.current) return

      const offset = monitor.getSourceClientOffset()
      if (!offset) return

      const cardRect = ref.current?.getBoundingClientRect()

      const sticker: StickerT = {
        id: item.id ?? uuid(),
        typeID: item.typeID,
        x: (offset.x - cardRect.x) / cardRect.width,
        y: (offset.y - cardRect.y) / cardRect.height,
        rotation: item.rotation,
      }

      setCardStickers((curr) => {
        const existingIndex = curr.findIndex(
          (sticker) => sticker.id === item.id,
        )
        return existingIndex === -1
          ? [...curr, sticker]
          : [
              ...curr.slice(0, existingIndex),
              ...curr.slice(existingIndex + 1),
              sticker,
            ]
      })

      if (item.source === 'screen')
        setScreenStickers((curr) =>
          curr.filter((sticker) => sticker.id !== item.id),
        )
    },
  })

  return React.useMemo(
    () =>
      // @ts-expect-error
      mergeRefs([ref, drop]),
    [drop],
  )
}

type CardProps = {
  isFlatReplica?: boolean
  withOutline?: boolean
  withStartOverButton?: boolean
} & BoxProps<'div'>

export const Card = ({
  isFlatReplica = false,
  withOutline = true,
  withStartOverButton = true,
  ...props
}: CardProps) => {
  const [cardImageIndex, setCardImageIndex] = useRecoilState(
    state.cardImageIndex,
  )
  const nextCardImage = () =>
    setCardImageIndex((curr) => (curr + 1) % CARD_IMAGE_URLS.length)
  const prevCardImage = () =>
    setCardImageIndex((curr) =>
      curr - 1 < 0 ? CARD_IMAGE_URLS.length - 1 : curr - 1,
    )
  const activeCardImageUrl = CARD_IMAGE_URLS[cardImageIndex]

  const dropRef = useCardDrop()
  const [cardStickers, setCardStickers] = useRecoilState(state.cardStickers)
  const hasStickers = cardStickers.length > 0

  const clearStickers = () => setCardStickers([])

  const [, setCardSize] = useRecoilState(state.cardSize)
  const [measure, bounds] = useMeasure()

  const styles = useStyles(styleRefs)
  const commonStyles = useCommonStyles()

  React.useEffect(() => {
    if (!isFlatReplica) setCardSize(bounds.width)
  }, [bounds.width, setCardSize, isFlatReplica])

  return (
    <Box
      // @ts-expect-error
      ref={isFlatReplica ? undefined : mergeRefs([dropRef, measure])}
      as={AspectRatio}
      x={1}
      y={1}
      {...props}
    >
      <Box
        className={clsx(
          styles.kraftBackground,
          !isFlatReplica && commonStyles.shadow[2],
        )}
        {...props}
        styles={{
          backgroundColor: 'brown.60',
          width: 'full',
          height: 'full',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          borderRadius: withOutline ? '13px' : undefined,
          backgroundSize: '100%',
        }}
      />

      <Box
        as="img"
        src={activeCardImageUrl}
        draggable={false}
        styles={{
          position: 'absolute',
          width: 'full',
          height: 'full',
          left: 0,
          top: 0,
        }}
      />

      {withOutline && (
        <Box
          styles={{
            borderWidth: ['2px', null, '3px'],
            borderColor: 'black',
            borderRadius: '10px',
            borderStyle: 'solid',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            marginTop: [-1.5, -2, -2.5],
            marginLeft: [-1.5, -2, -2.5],
            marginBottom: [1.5, 2, 2.5],
            marginRight: [1.5, 2, 2.5],
            pointerEvents: 'none',
          }}
        />
      )}

      <Box
        styles={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        {cardStickers.map((sticker) => (
          <CardSticker
            key={sticker.id}
            id={sticker.id}
            typeID={sticker.typeID}
            x={sticker.x}
            y={sticker.y}
            rotation={sticker.rotation}
          />
        ))}
      </Box>

      {!isFlatReplica && (
        <>
          <Box className={styles.prevCardButtonPosition}>
            <VisuallyHidden>Previous card image</VisuallyHidden>
            <ChevronButton variant="left" onClick={prevCardImage} />
          </Box>
          <Box className={styles.nextCardButtonPosition}>
            <VisuallyHidden>Next card image</VisuallyHidden>
            <ChevronButton variant="right" onClick={nextCardImage} />
          </Box>
        </>
      )}

      {withStartOverButton && (
        <Box
          className={styles.startOverButtonPosition}
          styles={{
            pointerEvents: hasStickers ? 'auto' : 'none',
            opacity: hasStickers ? 100 : 0,
            transitionProperty: 'opacity',
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeOut',
          }}
        >
          <StartOverButton onClick={clearStickers} />
        </Box>
      )}
    </Box>
  )
}
