import * as React from 'react'
import { useRecoilState } from 'recoil'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'
import clsx from 'clsx'

import { useStickerTypesByName } from '../hooks/useStickerTypesByName'
import * as state from '../state'

import { Text } from './Text'
import { DummySticker } from './DummySticker'
import { Icon } from './Icon'
import { ReactComponent as Asset2020SVG } from '../assets/2020.svg'

import * as styleRefs from './Header.treat'
import { AspectRatio } from '@walltowall/siamese'
import { useCommonStyles } from '../hooks/useCommonStyles'

export const Header = () => {
  const [, setModal] = useRecoilState(state.modal)

  const socialHandler = () => setModal({ isOpen: true, type: 'social' })

  const stickerTypes = useStickerTypesByName()
  const styles = useStyles(styleRefs)
  const commonStyles = useCommonStyles()

  return (
    <Box
      as="header"
      styles={{
        color: 'black',
        paddingTop: [5, 10],
        paddingBottom: [6, 8, 16, 20],
        position: 'relative',
        display: 'grid',
        justifyItems: 'center',
        gridAutoFlow: [null, null, 'column'],
        justifyContent: 'center',
        alignItems: 'center',
        gap: 7,
      }}
    >
      <Box
        className={styles.wwStickerPosition}
        styles={{
          position: ['absolute', null, 'static'],
          width: ['4rem', '5.5rem'],
          zIndex: 2,
        }}
      >
        <DummySticker
          typeID={stickerTypes['ww'].id}
          rotation={-15}
          staticWidth="full"
        />
      </Box>
      <Text
        variant="sansCond-20-64"
        styles={{
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'heavy',
          marginLeft: [4, 0],
          maxWidth: [null, '22ch', 'none'],
        }}
      >
        Occasional Greetings Generator
      </Text>
      <Box
        as={Asset2020SVG}
        className={styles.label2020Position}
        styles={{
          width: ['3.625rem', '5rem'],
          position: ['absolute', null, 'static'],
          top: '100%',
          left: '50%',
          zIndex: 2,
        }}
      />
      <Box
        as={AspectRatio}
        x={1}
        y={1}
        className={clsx(styles.wwSocialsPosition, commonStyles.shadow[2])}
        styles={{
          width: '2.5rem',
          display: [null, null, 'none'],
          position: 'absolute',
          zIndex: 2,
        }}
      >
        <Box
          as="button"
          onClick={socialHandler}
          styles={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRadius: '50%',
            width: 'full',
            height: 'full',
            transform: 'rotate(5deg)',
          }}
        >
          <Icon name="share" styles={{ color: 'skyblue.75', width: '5/12' }} />
        </Box>
      </Box>
    </Box>
  )
}
