import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { AspectRatio } from '@walltowall/siamese'
import { Box, BoxProps } from '@walltowall/calico'

import * as state from '../state'

import { Card } from '../components/Card2'
import AssetOgImageBg from '../assets/og-image-bg.png'

type OpenGraphImageProps = Omit<BoxProps<'div'>, 'as'>

export const OpenGraphImage = (props: OpenGraphImageProps) => {
  const cardSize = useRecoilValue(state.cardSize)

  return (
    <Box
      as={AspectRatio}
      x={1200}
      y={630}
      {...props}
      styles={{ overflow: 'hidden', backgroundColor: 'debug' }}
      style={{
        width: (1200 / 630) * cardSize,
        backgroundImage: `url(${AssetOgImageBg})`,
        backgroundSize: 'cover',
      }}
    >
      <Box
        style={{
          width: cardSize,
          marginLeft: 'auto',
          marginRight: 'auto',
          transform: 'scale(0.89)',
        }}
      >
        <Card isFlatReplica={true} />
      </Box>
    </Box>
  )
}
