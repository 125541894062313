import * as React from 'react'
import { useDrag } from 'react-dnd'
import { BoxProps } from '@walltowall/calico'

import { DragType, CardStickerDragItem } from '../types'
import { Sticker } from './Sticker'
import { sheetStickerVariants } from './SheetSticker'
import { STICKER_ROTATION_RANGE } from '../constants'
import { randomBetween } from '../lib/randomBetween'

type Props = {
  typeID: string
  x?: number
  y?: number
  rotation?: number
  staticWidth?: NonNullable<BoxProps['styles']>['width']
}

const dummyStickerVariants = {
  idle: {
    opacity: 1,
    transition: {
      duration: 0,
    },
  },
  dragging: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
}

export const DummySticker = ({
  typeID,
  x,
  y,
  rotation: passedRotation,
  staticWidth,
}: Props) => {
  const rotationRef = React.useRef(randomBetween(...STICKER_ROTATION_RANGE))
  const rotation = passedRotation ?? rotationRef.current

  const [state, setState] = React.useState<keyof typeof sheetStickerVariants>(
    'idle',
  )

  const [, drag] = useDrag({
    item: {
      type: DragType.Sticker,
      typeID,
      rotation: randomBetween(...STICKER_ROTATION_RANGE),
    } as CardStickerDragItem,
    begin: () => setState('dragging'),
    end: () => setState('idle'),
  })

  return (
    <Sticker
      ref={drag}
      animate={state}
      variants={dummyStickerVariants}
      style={{
        position: x || y ? 'absolute' : undefined,
        left: x,
        top: y,
        filter: 'drop-shadow(0 1px 0px rgba(0, 0, 0, 0.3))',
      }}
      rotation={rotation}
      scaled={false}
      typeID={typeID}
      staticWidth={staticWidth}
    />
  )
}
