import * as React from 'react'
import html2canvas from 'html2canvas'
import { useRecoilState } from 'recoil'

import {
  HTML2CANVAS_CARD_ID,
  HTML2CANVAS_OG_IMAGE_ID,
  __BROWSER__,
} from '../constants'
import * as state from '../state'

const html2canvasAndUpload = async (
  id: string,
  targetWidth: number,
): Promise<string | undefined> => {
  const el = document.querySelector(`#${id}`) as HTMLDivElement
  if (!el) return

  const canvas = await html2canvas(el, {
    scale: targetWidth / el.clientWidth,
  })
  const dataUrl = canvas.toDataURL()

  const formData = new FormData()
  formData.append('file', dataUrl)
  formData.append('upload_preset', 'lk4oio6h')

  const uploadRes = await fetch(
    'https://api.cloudinary.com/v1_1/walltowall/image/upload',
    {
      method: 'POST',
      body: formData,
    },
  )
  const data = await uploadRes.json()

  return data.url
}

export const useShareButton = () => {
  const [, setModal] = useRecoilState(state.modal)

  const shareHandler = React.useCallback(async () => {
    setModal({ isOpen: true, type: 'share', payload: { isLoading: true } })

    if (__BROWSER__) window.scroll(0, 0)

    const cardUrl = await html2canvasAndUpload(HTML2CANVAS_CARD_ID, 1200)
    const ogImageUrl = await html2canvasAndUpload(HTML2CANVAS_OG_IMAGE_ID, 1200)

    setModal({
      isOpen: true,
      type: 'share',
      payload: {
        isLoading: false,
        previewImageUrl: cardUrl,
        ogImageUrl,
      },
    })
  }, [setModal])

  return shareHandler
}
