import * as React from 'react'
import { Box } from '@walltowall/calico'
import clsx from 'clsx'

import { WW_INSTAGRAM_URL, WW_FACEBOOK_URL, WW_TWITTER_URL } from '../constants'
import { useCommonStyles } from '../hooks/useCommonStyles'

import { Icon } from './Icon'

const variants = {
  facebook: {
    url: WW_FACEBOOK_URL,
    icon: 'facebook',
    iconWidth: '4/12',
  },
  instagram: {
    url: WW_INSTAGRAM_URL,
    icon: 'instagram',
    iconWidth: '6/12',
  },
  twitter: {
    url: WW_TWITTER_URL,
    icon: 'twitter',
    iconWidth: '7/12',
  },
} as const

type SocialButtonProps = {
  variant: keyof typeof variants
}

export const SocialButton = ({ variant: variantName }: SocialButtonProps) => {
  const commonStyles = useCommonStyles()

  const variant = variants[variantName]

  return (
    <Box
      as="a"
      href={variant.url}
      target="_blank"
      className={clsx(commonStyles.shadow[2], commonStyles.focusYMovement)}
      styles={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '50%',
        backgroundColor: 'black',
        color: 'white',
        transitionProperty: 'transform',
        transitionDuration: 'normal',
        transitionTimingFunction: 'easeOut',
        padding: 0.5,
      }}
    >
      <Icon name={variant.icon} styles={{ width: variant.iconWidth }} />
    </Box>
  )
}
