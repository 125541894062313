import * as React from 'react'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import { Icon, IconProps } from './Icon'
import { useCommonStyles } from '../hooks/useCommonStyles'

const defaultElement = 'button'

const variants = {
  idle: {
    color: 'gray.40',
    backgroundColor: 'white',
  },
  active: {
    color: 'white',
    backgroundColor: 'salmon',
  },
} as const

export type SheetSelectorButtonProps<E extends React.ElementType> = {
  variant?: keyof typeof variants
  size?: NonNullable<BoxProps['styles']>['width'] &
    NonNullable<BoxProps['styles']>['height']
  icon: IconProps['name']
} & Omit<BoxProps<E>, 'size'>

export const SheetSelectorButton = <
  E extends React.ElementType = typeof defaultElement
>({
  variant: variantName = 'idle',
  size = '1.5rem',
  icon: iconName,
  className,
  ...props
}: SheetSelectorButtonProps<E>) => {
  const variant = variants[variantName]

  const commonStyles = useCommonStyles()

  return (
    <Box
      as={defaultElement}
      {...props}
      className={clsx(commonStyles.shadow[1], className)}
      styles={{
        backgroundColor: variant.backgroundColor,
        color: variant.color,
        width: size,
        height: size,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transitionProperty: 'colorAndBackgroundColor',
        transitionDuration: 'normal',
        transitionTimingFunction: 'easeOut',
        ...props.styles,
      }}
      focusStyles={{
        backgroundColor: 'salmon',
        color: 'white',
        ...props.focusStyles,
      }}
      hoverStyles={{
        backgroundColor: 'salmon',
        color: 'white',
        ...props.hoverStyles,
      }}
    >
      <Icon name={iconName} styles={{ width: '6/12', height: '6/12' }} />
    </Box>
  )
}
