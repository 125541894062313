import * as React from 'react'
import { motion } from 'framer-motion'

import { Sticker } from './Sticker'
import { StickerShadow } from './StickerShadow'

const stickerVariants = {
  initial: {
    scale: 1,
    y: '0%',
    transition: {
      bounce: 0,
    },
  },
  enter: {
    scale: 1.25,
    transition: {
      bounce: 0,
    },
  },
  exit: {
    scale: 1,
    y: '0%',
    transition: {
      bounce: 0,
    },
  },
}

const shadowVariants = {
  initial: {
    x: '0%',
    y: '0%',
    scale: 0.7,
    filter: 'drop-shadow(0 1px 0px rgba(0, 0, 0, 0.3))',
  },
  enter: {
    x: '0%',
    y: '30%',
    scale: 1.1,
    filter: 'drop-shadow(0 0 0px rgba(0, 0, 0, 0))',
  },
  exit: {
    x: '0%',
    y: '0%',
    scale: 0.7,
    filter: 'drop-shadow(0 1px 0px rgba(0, 0, 0, 0.3))',
  },
}

type DragLayerStickerProps = {
  x: number
  y: number
  typeID: string
  rotation: number
}

export const DragLayerSticker = ({
  x,
  y,
  typeID,
  rotation,
}: DragLayerStickerProps) => {
  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 3,
        x,
        y,
        transformOrigin: 'center',
        pointerEvents: 'none',
      }}
    >
      <StickerShadow
        variants={shadowVariants}
        scaled={true}
        typeID={typeID}
        rotation={rotation}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <Sticker
        variants={stickerVariants}
        style={{ position: 'relative' }}
        scaled={true}
        typeID={typeID}
        rotation={rotation}
      />
    </motion.div>
  )
}
