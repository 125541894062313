import * as React from 'react'
import { AnimatePresence } from 'framer-motion'
import { useDragLayer, XYCoord } from 'react-dnd'

import { DragItem } from '../types'
import { DragLayerSticker } from './DragLayerSticker'

export const DragLayer = () => {
  const previousOffsetRef = React.useRef<XYCoord | null>(null)
  const dragLayerProps = useDragLayer((monitor) => {
    const offset = monitor.getSourceClientOffset()
    const isDragging = monitor.isDragging()
    const item = monitor.getItem() as DragItem | undefined

    if (offset) previousOffsetRef.current = offset

    return {
      isDragging,
      item,
      x: offset?.x ?? previousOffsetRef.current?.x,
      y: offset?.y ?? previousOffsetRef.current?.y,
    }
  })

  return (
    <AnimatePresence>
      {dragLayerProps.isDragging && dragLayerProps.item && (
        <DragLayerSticker
          x={dragLayerProps.x!}
          y={dragLayerProps.y!}
          typeID={dragLayerProps.item.typeID}
          rotation={dragLayerProps.item.rotation}
        />
      )}
    </AnimatePresence>
  )
}
