import * as React from 'react'

import { useStickerTypes } from './useStickerTypes'
import { StickerSpec } from '../types'

export const useStickerTypesByName = (): Record<string, StickerSpec> => {
  const stickerTypes = useStickerTypes()

  return React.useMemo(
    () =>
      stickerTypes.reduce((acc, sticker) => {
        acc[sticker.name] = sticker
        return acc
      }, {} as Record<string, StickerSpec>),
    [stickerTypes],
  )
}
